//react
import React from "react"
import PropTypes from "prop-types";

import _ from "lodash";
//grommet
import {Box, CheckBox, Heading, Paragraph, Text} from "grommet";
//hypergrade
import HyperGrade from '../hg-config';
import Pill from "./Pill";


import CommandLineArguments from "./CommandLineArguments";

var classNames = require('classnames');

class CharacterMiner {
  constructor(segmentArray) {
    this.segmentArray = segmentArray;
    this.segmentIndex = 0;
    this.characterIndex = 0;

    this.grabNextCharToCompare = this.grabNextCharToCompare.bind(this);
  }


  grabNextCharToCompare() {
    if (this.segmentArray) {
      if (this.segmentIndex < this.segmentArray.length) {
        if (this.characterIndex < this.segmentArray[this.segmentIndex].line.length) {
          return {
            character: this.segmentArray[this.segmentIndex].line[this.characterIndex++],
            type: this.segmentArray[this.segmentIndex].type
          }
        } else {
          this.segmentIndex++;
          this.characterIndex = 0;
          return this.grabNextCharToCompare();
        }
      }
    }
    return false;
  }
}

class CharacterByCharacterCombined extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      //by default, we show the student output if it's supplied
      //no need to check for teacher output because it's required
      showStudentOutput: !!props.studentOutput
    };

    this.keyVal = 0;

    this.process = this.process.bind(this);
    this.processCharacter = this.processCharacter.bind(this);

    this.getNextKey = () => this.keyVal++;
    this.toggle = () => this.setState({showStudentOutput: !this.state.showStudentOutput});
    this.showStudentOutput = () => this.state.showStudentOutput;

    this.caseComplete = () => this.props.passed === true;
    this.caseFailed = () => this.props.passed === false;
    this.hasAttempt = () => this.props.passed !== undefined;

    this.refresh = this.refresh.bind(this);
    this.limitLength = this.limitLength.bind(this);
    this.countNumCharsInMixedOutput = this.countNumCharsInMixedOutput.bind(this);
    this.truncateTo = this.truncateTo.bind(this);
  }

  componentDidMount() {
    this.refresh();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    //if we got some fresh student output
    if (prevProps.studentOutput !== this.props.studentOutput) {
      //go in to compare mode if we have output, go back if not
      this.setState({showStudentOutput: !!this.props.studentOutput}, this.refresh);
    } else if (prevState.showStudentOutput !== this.state.showStudentOutput
      || !_.isEqual(prevProps, this.props)) { //if we toggled OR some props changed
      //just refresh
      this.refresh();
    }
  }

  refresh() {
    this.setState({tree: this.process()});
  }

  countNumCharsInMixedOutput(mixedOutput) {
    let numChars = 0;
    for (let x = 0; x < mixedOutput.length; x++) {
      numChars += mixedOutput[x].line.length;
    }
    return numChars;
  }

  truncateTo(mixedOutput, max) {

    if (mixedOutput && Array.isArray(mixedOutput)) {
      max = parseInt(max);
      let numChars = 0;
      for (let x = 0; x < mixedOutput.length; x++) {
        numChars += mixedOutput[x].line.length;
        if (numChars > max) {
          mixedOutput[x].line = mixedOutput[x].line.substring(mixedOutput[x].line, max - (numChars - mixedOutput[x].line.length));
          mixedOutput[x].line += '\u2026\u04F8';
          mixedOutput[x].truncated = true;
          mixedOutput = mixedOutput.slice(0, x + 1);
        }
      }
    } else {
      console.log("Warning: Could not limit");
    }

    return mixedOutput;
  }

  limitLength(mixedOutputToTruncate, mixedOutputRequired) {
    let numCharsTeacher = this.countNumCharsInMixedOutput(mixedOutputRequired);
    return this.truncateTo(mixedOutputToTruncate, numCharsTeacher + 500);
  }

  process() {

    //hidden test cases don't need processing
    if (this.props.hide) {
      return <Paragraph margin={"none"}>{HyperGrade.hardcoding}</Paragraph>
    }

    //determine what we're showing and what we're comparing against
    let toShow = this.props.teacherOutput, toCompareAgainst;
    if (this.showStudentOutput()) {
      toShow = this.props.studentOutput;
      toCompareAgainst = this.props.teacherOutput;
    }

    //if we got a string
    if (typeof toShow === 'string')
      toShow = HyperGrade.translateStringToMixedOutput(toShow);
    if (typeof toCompareAgainst === 'string')
      toCompareAgainst = HyperGrade.translateStringToMixedOutput(toCompareAgainst);

    if (this.showStudentOutput()) {
      //student code might be way to long (infinite loop), which causes hanging
      //so, limit the output based on what the teacher is doing
      toShow = this.limitLength(toShow, toCompareAgainst);
    }

    //will hold the polished input/output
    let tree = [];

    let toShowMiner = new CharacterMiner(toShow);
    let toCompareMiner = new CharacterMiner(toCompareAgainst);

    let next = toShowMiner.grabNextCharToCompare();
    while (next) {
      let nextOther = toCompareMiner.grabNextCharToCompare();

      if (this.showStudentOutput()) {
        this.processCharacter(tree, next.character, next.type, nextOther ? nextOther.character : false);
      } else {
        this.processCharacter(tree, next.character, next.type);
      }

      next = toShowMiner.grabNextCharToCompare();
    }

    return tree;
  }


  processCharacter(tree, current, extraClass, other) {

    let c = current;
    let classArr = [];

    if (extraClass) {
      classArr.push(extraClass);
    }

    if (current === '\n') {
      c = '\\n';
      classArr.push('special');
      //the "output" new line is just displayed at \n
      //the "input" new line is displayed at ENTER
      if (classArr.findIndex(current => current === "input") >= 0) {
        classArr.push('enter');
        c = "enter";
      }
    } else if (current === '\t') {
      c = '\\t';
      classArr.push('special tab');
    } else if (current === ' ') {
      c = '\u00A0';
      classArr.push('space');
    } else if (current === '\u04F8') {
      classArr.push('special');
      classArr.push('beyond-teacher-output');
      c = "output too long";
    }

    if (other !== undefined) {
      if (current === other) {
        classArr.push("match");
      } else if (current === false) { //student output is too long
        classArr.push("mismatch");
      } else {
        classArr.push("mismatch");
      }
    }

    tree.push(<u key={"cbyc-" + this.getNextKey()}
                 className={classArr.length === 0 ? null : classArr.join(" ")}>{c}</u>);
    if (current === '\n' || current.toLowerCase() === "enter") {
      tree.push(<br key={"cbyc-" + this.getNextKey()}/>);
    }
  }

  render() {
    let headerItems = [];

    if (this.props.header) {
      headerItems.push(<Heading key="header" level="4" margin="none">{this.props.header}</Heading>);
    }

    let pills = [];
    if (this.props.runResult) {

      if (this.props.passed) {
        pills.push(<Pill key="pill-passed" status={"good"} label="Passed!"/>);
      } else {

        if (this.props.runResult.returnCode === HyperGrade.errCannotContinue) {
          pills.push(<Pill key="p-crash" status={"bad"}
                           label={"Could not run because earlier test case took too long"}/>);
        } else if (this.props.runResult.errTimedOut) {
          pills.push(<Pill key="p-crash" status={"bad"}
                           label={"Took too long to run"}/>);
        } else {
          pills.push(<Pill key="pill-fail" status={"bad"} label="Failed"/>);
        }

      }
    }

    if (pills.length) {
      headerItems.push(
        <Box key="pill-list" direction="row" gap="small">
          {pills}
        </Box>
      );
    }

    if (this.props.studentOutput && !this.caseComplete()) {
      headerItems.push(
        <Box key="toggle" direction={"row"} gap="medium" background={"light-3"} pad={"small"}>
          <Text>Show what's missing</Text>
          <CheckBox checked={!this.state.showStudentOutput} toggle onChange={this.toggle}/>
        </Box>
      );
    }

    let bar;
    if (headerItems.length) {
      bar = <Box direction="row" gap="small" align="center">{headerItems}</Box>
    }

    return (
      <Box gap="small">
        {bar}
        <CommandLineArguments command_line_arguments={this.props.command_line_arguments}/>
        <div className={classNames({"character-by-character": true, "plain": this.props.plain})}>
          {this.state.tree}
        </div>
      </Box>
    )
  }
}

//Modes:

CharacterByCharacterCombined.propTypes = {

  //to support both mixed output and the older string style
  teacherOutput: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  studentOutput: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),

  header: PropTypes.string,
  passed: PropTypes.bool, //true = complete, false = failed, undefined = no file/attempt

  hide: PropTypes.bool,

  runResult: PropTypes.object,
  command_line_arguments: PropTypes.string
};

CharacterByCharacterCombined.defaultProps = {};

export default CharacterByCharacterCombined